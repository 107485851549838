import axios, { AxiosRequestConfig } from 'axios'
import { NOIRLabPaginatedArchiveList } from '@/js/types'
// Remember to generate the api-client using "npm run gen-api"
import { Configuration, ReleasesApi, MediaApi, AnnouncementsApi } from '@/js/api-client'
import axiosInstance from '@/js/api/axios'

const config = new Configuration({ basePath: 'https://noirlab.edu' })

export const releasesApi = new ReleasesApi(config, undefined, axiosInstance)
export const announcementsApi = new AnnouncementsApi(config, undefined, axiosInstance)
export const mediaApi = new MediaApi(config, undefined, axiosInstance)

export function getNOIRLabNews(page: number = 1, axiosConfig?: AxiosRequestConfig, feedUrl?) {
  return axios.get<NOIRLabPaginatedArchiveList>(`${feedUrl}page/${page}/`, axiosConfig)
}

export function getNOIRLabAnnouncements(page: number = 1, axiosConfig?: AxiosRequestConfig, feedUrl?) {
  return axios.get<NOIRLabPaginatedArchiveList>(`${feedUrl}page/${page}/`, axiosConfig)
}

export function getNOIRLabVideos(page: number = 1, axiosConfig?: AxiosRequestConfig, feedUrl?: string) {
  return axios.get<NOIRLabPaginatedArchiveList>(`${feedUrl}page/${page}/`, axiosConfig)
}
